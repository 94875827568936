<template>
    <div class="">
        <div>
            <el-button @click="select = '验号中心'" type="primary" :plain="select != '验号中心'">验号中心</el-button>
            <el-button @click="select = '卡首屏系列'" type="primary" :plain="select != '卡首屏系列'">卡首屏系列</el-button>
            <el-button @click="select = '手淘卡屏'" type="primary" :plain="select != '手淘卡屏'">手淘卡屏</el-button>
        </div>
        <div class="text-right more-pr">
            <span class="point fc-666" @click="moreClick">更多 ></span>
        </div>
        <div class="marginT-10 flex flex-wrap">
            <div class="item" v-for="(item, index) in allItem[select]" :key="index">
                <div class="fs-18 fc-666 fw-bold">{{item.title}}</div>
                <div class="flex marginT-10 flex-between align-center">
                    <div class="flex">
                        <el-rate :value="5" disabled></el-rate>
                        <el-divider direction="vertical"></el-divider>
                        <div class="fs-12 fc-999">666人在用</div>
                    </div>
                    <img class="img-zan" src="https://pic.rmb.bdstatic.com/bjh/1d78d216d72575f7a71e0dc61edcfa73.png" alt="">
                </div>
                <div class="fs-12 fc-999 marginT-10 flex align-center">
                    <div>{{item.desc}}</div>
                    <i class="el-icon-arrow-right fc-theme fs-18"></i>
                </div>

                <div class="item-mask bgc-theme">
                    <div class="fs-15">{{item.title}}：</div>
                    <div class="fs-14 marginT-10">{{item.desc}}</div>
                    <div class="text-right marginT-10">
                        <el-button size="small" plain @click="goClick(item)">查看详情</el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        type: { // type === 'beian' 则说明是备案页过来的
            type: String,
            default: ""
        }
    },
    data() {
        return {
            select: '验号中心',
            allItem: {
                '验号中心': [
                    {
                        title: '旺旺照妖镜',
                        desc: '通过实时查询官方对指定淘宝号推荐的搜索词，获取到淘宝相关的标签',
                        url: '/home'
                    },
                    {
                        title: '搜索权重推送',
                        desc: '通过官方搜索推荐算法，首页搜索结果推送，将单品推送到指定用户的搜索首屏上，提高搜索...',
                        url: '/weightpush'
                    },
                    {
                        title: '防P图验号',
                        desc: '可以验地址、性别、日常购买类目、我的足迹、已购买宝贝和是否有评价违规等记录',
                        url: '/validPicture'
                    },
                    {
                        title: '指数换算',
                        desc: '组合指数计算工具可以多指标同时换算，并同步组合计算出日常运营所需要的相应高阶...',
                        url: '/transformData'
                    },
                    {
                        title: '淘宝客订单查询',
                        desc: '根据淘宝后台检测订单是否为淘宝客订单，杜绝佣金损失',
                        url: '/tbguest'
                    },
                    {
                        title: 'DSR智能计算器',
                        desc: '输入当前DSR基础数据与目标数据，自动计算还需要多少单5分的好评！店铺单量目标...',
                        url: '/checkNum/dsrCal'
                    },
                ],
                '卡首屏系列': [
                    {
                        title: '关键词卡首屏',
                        desc: '输入相应的关键词和商品链接，会免费生成一个二维码。买家通过扫描这个二维码，商品会在...',
                        url: '/checkNum/firstPause'
                    },
                    {
                        title: '手淘卡屏找相似',
                        desc: '此功能通过纯API接口模式，生成特制的二维码。买家通过扫描二维码，可进入淘宝的找相似页面...',
                        url: '/checkNum/similarImport'
                    },
                    // {
                    //     title: '洋淘秀卡首屏',
                    //     desc: '过洋淘秀卡首屏扫码后出现在洋淘秀首屏，在生意参谋后台路径为【淘内免费-手淘洋淘秀】，配合...',
                    //     url: '/foreignTao'
                    // },
                    {
                        title: '旺旺强行打标',
                        desc: '只需输入要打标的宝贝链接，以及要推送宝贝的目标买家旺旺账号，执行打标后，打标成...',
                        url: '/marking'
                    },
                    {
                        title: '拼多多打标',
                        desc: '提前对大量多多号打标，并配合做单，可提高商品进入拼多多首页的机会，结合拼多多的千人千面机制...',
                        url: '/pddScreen'
                    },
                    {
                        title: '猜你喜欢',
                        desc: '商家可通过此功能，在生意参谋后台添加一条来源为手淘推荐的访客数据...',
                        url: '/tbmobile/guess'
                    },
                ],
                '手淘卡屏': [
                    {
                        title: '有好货',
                        desc: '有好货位于淘宝的首页位置，流量大。商家可以通过我们网站上提供的有好货功能，在生意参谋中插...',
                        url: '/tbmobile/nicegoods'
                    },
                    {
                        title: '每日好店',
                        desc: '每日好店位于淘宝的首页位置，流量大。商家可以通过我们网站上提供的每日好店功能，在生意参谋中插...',
                        url: '/tbmobile/dayshops'
                    },
                    {
                        title: 'iFashion',
                        desc: 'iFashion是潮流店铺或商品的代表，紧跟时尚步伐，流量大。商家可以通过我们网站上提供的iFashion功能',
                        url: '/tbmobile/iFashion'
                    },
                    {
                        title: '淘抢购',
                        desc: '淘抢购位于淘宝的首页位置，流量大。商家可以通过我们网站上提供的淘抢购功能，在生意参谋中...',
                        url: '/tbmobile/robshop'
                    },
                    {
                        title: '亲宝贝',
                        desc: '亲宝贝流量大，商家可以通过我们网站上提供的亲宝贝功能，在生意参谋中',
                        url: '/tbmobile/probaby'
                    },
                    // {
                    //     title: '潮玩城',
                    //     desc: '潮玩城流量大，商家可以通过我们网站上提供的潮玩城功能，在生意参谋中插入...',
                    //     url: '/tbmobile/tideplay'
                    // },
                ]
            }
        }
    },
    created() {

    },
    methods: {
        goClick(item) {
            if (this.type && this.type === 'beian') {
                window.openLogin('beian')

                return false;
            }
            this.$router.push(item.url)
        },

        moreClick() {
            if (this.type && this.type === 'beian') {
                window.openLogin('beian')

                return false;
            }
            this.$router.push(this.allItem[this.select][0].url)
        },
    },
}
</script>

<style lang="scss" scoped>
@keyframes hoverzoom {
  from {top: 135px;}
  to {top: 0;}
}
.item {
    position: relative;
    flex-basis: 30.27%;
    border: 1px solid #ddd;
    padding: 10px 14px;
    border-radius: 6px;
    margin: 10px 10px 10px 0;
    overflow: hidden;
    &:nth-child(3) {
        margin-right: 0;
    }
    &:nth-child(6) {
        margin-right: 0;
    }

    &:hover {
        .item-mask {
            animation-duration:.3s;
            animation-timing-function:ease-in;
            animation-name:hoverzoom;
            top: 0;
        }
    }
}
.item-mask {
    position: absolute;
    padding: 10px 12px;
    top: 135px;
    bottom: 0;
    left: 0;
    right: 0;
    color: #fff;
}
.more-pr {
    height: 10px;
}
.img-zan {
    width: 30px;
}
</style>
