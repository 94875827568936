<template>
    <div class="division text-center">
        <div class="fc-333 fw-bold fs-24">{{title}}</div>
        <div class="fc-999 fs-18">{{sub}}</div>
        <el-divider><i class="round bgc-theme"></i></el-divider>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        sub: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            // key: value
        }
    },
}
</script>

<style lang="scss" scoped>
.division {
    padding: 30px 0 15px 0;
}
.round {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
}
</style>