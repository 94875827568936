<template>
    <div>
        <div class="text-right fc-666"><span class="point" @click="goApi">更多 ></span></div>
        <div class="flex">
            <img class="point" @click="goApi()" src="https://pic.rmb.bdstatic.com/bjh/bd16d9a085e0182ba667248e027c6f42.jpeg" alt="">
            <div class="flex flex-wrap">
                <div v-for="(item, index) in doc.slice(0, 8)" :key="index" class="item text-center">
                    <img src="https://pic.rmb.bdstatic.com/bjh/e4e9c6d2b4dd623d0812b78b301cd6aa.png" alt="">
                    <div class="marginT-10 fc-666">{{item.api_name}}</div>
                    <div class="desc fc-999 fs-12">{{item.api_desc}}</div>
                    <el-button type="primary" plain size="small" class="marginT-10" @click="goPage(item)">立即接入</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    props: {
        doc: {
            type: Array,
            default: []
        },
        type: { // type === 'beian' 则说明是备案页过来的
            type: String,
            default: ""
        }
    },
    computed: {
        ...mapState(['configData']),
    },
    data() {
        return {
            // showList: []
        }
    },
    methods: {
        goApi() {
            if (this.type && this.type === 'beian') {
                window.openLogin('beian')

                return false;
            }

            if (this.isToolMainSite()) {
                window.open('http://doc.vv-tool.com')
            }else {
                window.open(this.configData.proxy_info.site_doc_host)
            }
        },
        goPage(item) {
            if (this.type && this.type === 'beian') {
                window.openLogin('beian')

                return false;
            }

            const path = `/taobao/${item.api_code}`
            if (this.isToolMainSite()) {
                window.open('http://doc.vv-tool.com' + path)
            }else {
                window.open(this.configData.proxy_info.site_doc_host + path)
            }
        }
    },
    mounted () {
        // this.getDoc()
    },
}
</script>

<style lang="scss" scoped>
.item {
    border: 1px solid #ddd;
    flex-basis: 217px;
    max-width: 217px;
    padding: 10px;
    box-sizing: border-box;
    img {
        width: 65px;
    }
    .desc {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
</style>
