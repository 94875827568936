<template>
    <div v-loading="loading" class="beian">
        <div class="header-beian"
            :style="{ 'background-color': info.proxy_info && info.proxy_info.site_theme ? info.proxy_info.site_theme : '#2c4167' }">
            <div class="header-content">
                <div style="display: flex; align-items: center">
                    <div class="logo" style="margin-right: 10px; font-size: 20px; color: #fff; font-weight: 600">{{
                        info.site_name }}博客</div>
                    <ul class="nav">
                        <li :class="{ active: activeIndex === '1' }">
                            <a href="javascript:;">首页</a>
                        </li>
                        <li :class="{ active: activeIndex === '2' }" @click="goLogin">
                            <a href="javascript:;">文章问答</a>
                        </li>
                        <li :class="{ active: activeIndex === '3' }" @click="goLogin">
                            <a href="javascript:;">电商工具</a>
                        </li>
                        <li :class="{ active: activeIndex === '4' }" @click="goLogin">
                            <a href="javascript:;">礼品发货</a>
                        </li>
                        <li :class="{ active: activeIndex === '5' }" @click="goLogin">
                            <a href="javascript:;">提升流量</a>
                        </li>
                        <li :class="{ active: activeIndex === '6' }" @click="goLogin">
                            <a href="javascript:;">API开放平台</a>
                        </li>
                    </ul>
                </div>
                <div class="handle-btns">
                    <!--                    <a href="javascript:;" @click="goLogin">登录</a>-->
                    <!--                    <a href="javascript:;" @click="registerClick">注册</a>-->
                </div>
            </div>
        </div>
        <div style="width: 1220px; margin: 0 auto">
            <div style="padding: 20px 0;">
                <el-button type="primary" plain size="small" @click="goLogin()">文章一</el-button>
                <el-button plain size="small" @click="goLogin()">文章二</el-button>
                <el-button plain size="small" @click="goLogin()">文章三</el-button>
                <el-button type="text" plain size="small" @click="goLogin()">更多干货</el-button>
            </div>
            <div class="widget-article">
                <h3 class="title">[虚假交易]被判为虚假交易怎么办,虚假交易怎么解决,做单被淘宝查到了怎么办,虚假交易申诉通过率</h3>
                <ul class="taglist-inline">
                    <li class="tagPopup">
                        <a class="tag" href="javascript:;" @click="goLogin()">被判虚假交易了怎么办,虚假交易如何申诉,淘宝虚假交易降权了怎么办?</a>
                    </li>
                </ul>
                <div class="content mt-10">
                    <div class="quote mb-20">
                        做淘宝的都知道，做单简直是一件必不可少的事情了。既然是做单，就一定会有风险，被淘宝判为虚假交易应该怎么办呢？
                    </div>
                    <div class="text-fmt">
                        <div>做淘宝的都知道，v兔做单简直是一件必不可少的事情了。既然是做单，就一定会有风险，被淘宝判为虚假交易应该怎么办呢？<br /></div>

                        <div>哈喽~大家好，我是四七。</div>

                        <div>今天，四七就来讲讲，关于虚假交易的那些事儿。</div>

                        <div><br /></div>

                        <div><span style="font-weight: bold">1.交易风险保障</span></div>

                        <div>
                            一般是新店短时间内交易了大量订单，淘宝为了保障双方的交易安全，会将交易金额进行15天的管控，这个和虚假交易没有什么关系，无需惊慌。
                        </div>

                        <div>店铺长时间没有经营，建议少量递增交易，比较保险。</div>

                        <div>出现这种情况，比较好的方法就是增加店铺保障金，停止比较大额的交易，一旦被管控后，v兔店铺一般会被隐形降权。</div>

                        <div><br /></div>

                        <div><span style="font-weight: bold">2.特殊类型订单、评论被删除</span></div>

                        <div>
                            没有申诉入口，一般是由转化订单造成的。出现这种情况，一般是店铺订单量递增地太快引起数据异常，淘宝会直接删除订单和评价。建议先降一降订单量，知道规格稍微大一点，再逐步递增订单量。
                        </div>

                        <div><br /></div>

                        <div><span style="font-weight: bold">3.降权</span></div>

                        <div>对于降权的处罚，是阿里的搜索部门下达的，而进行申诉的部门是稽查部门，所以一般成功率很低。</div>

                        <div>降权虽然看起来比店铺违规的处罚要低，但是由于申诉通过率很低，所以问题更严重。</div>

                        <div>对于降权的商品，除非是爆款，否则直接删除；不要下架，直接删除。</div>

                        <div><br /></div>

                        <div><span style="font-weight: bold">4.虚假交易提醒</span></div>

                        <div>一般是由于你店铺的订单比较好，所以发出这样的善意提醒；小店的话，淘宝小二一般都不care你。</div>

                        <div><br /></div>

                        <div><span style="font-weight: bold">5.订单违规纠正</span></div>

                        <div>凡是出现这种情况，都是不扣分的，不要被所谓的专业处理团队给骗了。一般是由稽查部下发的处罚通知，情节比较轻，申诉成功率较大。</div>

                        <div><br /></div>

                        <div>好了，今天的分享就到这里，关注我不迷路，下次见~</div>
                    </div>
                </div>
            </div>
            <division title="热门工具" sub="Popular tools"></division>
            <middle-cate type="beian"></middle-cate>
            <division title="电商工具" sub="Electricity tools"></division>
            <elTool type="beian"></elTool>
            <!--            <division title="流量中心" sub="Traffic center"></division>-->
            <!--            <trafficCenter type="beian"></trafficCenter>-->
            <!--            <division title="API开放平台" sub="API Open platform"></division>-->
            <!--            <apiSection :doc="api" type="beian"></apiSection>-->
        </div>
        <div style="text-align: center; color: #888; padding: 20px 0">
            备案号：<a class="fc-333" target="_blank" href="https://beian.miit.gov.cn/">{{ info.site_icp ? info.site_icp : '123'
            }}</a>
        </div>
    </div>
</template>

<script>
import appConfig from '../../mixin/appConfig'
import division from '../homepage/division/index'
import middleCate from '../homepage/middleFeature/index'
import elTool from '../homepage/elTool/index'
import trafficCenter from '../homepage/trafficCenter/index'
import apiSection from '../homepage/apiSection/index'

export default {
    mixins: [appConfig],
    components: {
        division,
        middleCate,
        elTool,
        trafficCenter,
        apiSection,
    },
    data() {
        return {
            loading: false, // 是否加载中
            showView: false, // 是否展示内容
            activeIndex: '1',
            info: null,
            api: [],
        }
    },
    async created() { },
    async mounted() {
        this.handleAff()
        // 在这里登录后需要跳转到相应的首页, 没有登录则展示此页面, 已经登陆则直接去相应的页面
        if (this.$store.state.phone && this.$store.state.userData.username) {
            console.log('是分站已登录')
            this.$router.replace({
                path: '/homepage',
            })
        } else {
            console.log('是分站未登录')
            let res = await this.getBeian() // +res.is_beian === 0
            console.log('beian', res)
            this.info = res
            // if (+res.is_beian === 0) {
            //     this.$router.replace({
            //         path: '/',
            //     })
            // }
        }

        this.getDoc()
    },
    methods: {
        async getBeian() {
            this.loading = true
            try {
                const res = await this.$fetch('/tool/apps/get-beian', {})

                return res.data
            } finally {
                this.loading = false
            }
        },

        goLogin() {
            window.openLogin('beian')
        },

        async getDoc() {
            const data = await this.$fetch('/doc/apps/docs?type=1')
            if (data.code == 0) {
                this.api = data.data
            }
        },

        // 注册
        registerClick() {
            window.openReg();
        },

        // 处理 URL 里面的 aff 参数, 主要用来做邀请链接绑定的
        handleAff() {
            if (this.$route.query.aff) {
                localStorage.aff = this.$route.query.aff
                this.$router.replace({
                    path: '/homepage',
                })
            }
        },
    },
}
</script>

<style lang="scss" scope>
.beian {
    top: 0;
    bottom: 0;
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
    top: 0;
    left: 0;
    width: 100vw;
    min-height: 100vh;
    padding: 0 !important;
    margin: 0 !important;
    background: #fff;
    text-align: left;
    z-index: 1112;

    a {
        color: #009a61;
        text-decoration: none;
    }
}

.header-beian {
    height: 56px;
    line-height: 56px;
    background-color: #2a8c70;

    .header-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 1280px;
        margin: 0 auto;

        .handle-btns {
            a {
                font-size: 16px;
                color: #fff;
                line-height: 56px;
                padding: 0 16px;
            }
        }

        .logo {}

        .nav {
            display: flex;
            align-items: center;

            li {
                // margin-right: 16px;
                padding: 0 16px;

                &.active {
                    background-color: #2b7a5c;
                }

                &:last-child {
                    margin-right: 0;
                }

                a {
                    font-size: 16px;
                    color: #fff;
                    line-height: 56px;
                }
            }
        }
    }
}

.beian-content {
    width: 1280px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    a {
        color: #009a61;
        text-decoration: none;
    }

    .left {
        flex: 1;
        background-color: #fff;
        padding: 10px;

        .h3,
        h3 {
            font-size: 24px;
        }

        .h1,
        .h2,
        .h3,
        h1,
        h2,
        h3 {
            margin-top: 20px;
            margin-bottom: 10px;
        }

        .media-body,
        .media-left,
        .media-right {
            display: table-cell;
            vertical-align: top;
        }

        .widget-user .media-heading {
            font-size: 16px;
            font-weight: 700;
        }

        .media-heading {
            margin-top: 0;
            margin-bottom: 5px;
        }

        .widget-article .quote {
            padding: 25px;
            background: #f3f5f9;
            line-height: 24px;
            overflow: hidden;
        }

        .mb-20 {
            margin-bottom: 20px;
        }

        .text-fmt {
            overflow: hidden;
            font-size: 14px;
            line-height: 1.6;
            word-wrap: break-word;
        }
    }

    .right {
        margin-left: 20px;
        width: 320px;

        .widget-user {
            padding: 15px;
            background-color: #fff;
        }

        .media:first-child {
            margin-top: 0;
        }

        .media,
        .media-body {
            overflow: hidden;
            zoom: 1;
        }

        .media-left,
        .media>.pull-left {
            padding-right: 10px;
        }

        .pull-left {
            float: left !important;
        }

        .avatar-64 {
            width: 64px;
            height: 64px;
            border-radius: 50%;
        }

        .media-object {
            display: block;
        }

        img {
            vertical-align: middle;
        }
    }
}
</style>
