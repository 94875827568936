<template>
    <div class="flex">
        <div v-for="(item, index) in platList" :key="index" class="item text-center">
            <img :src="item.img" alt="">
            <div class="title">{{item.title}}</div>
            <div class="btn" @click="goClick(item)">立即使用</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        type: { // type === 'beian' 则说明是备案页过来的
            type: String,
            default: ""
        }
    },
    data() {
        return {
            platList: [
                {
                    title: '淘宝流量任务',
                    url: '/flow/taobao',
                    img: 'https://pic.rmb.bdstatic.com/bjh/b493d806655619539b71ee0b1fbd548b.png'
                },
                {
                    title: '京东流量任务',
                    url: '/flow/jd',
                    img: 'https://pic.rmb.bdstatic.com/bjh/7eae4f5dfac77749bea7c64f97905a8d.png'
                },
                {
                    title: '拼多多流量任务',
                    url: '/flow/pdd',
                    img: 'https://pic.rmb.bdstatic.com/bjh/9beb0a292137795cd872d847c432791e.png'
                },
                {
                    title: '抖音流量任务',
                    url: '/flow/dy',
                    img: 'https://pic.rmb.bdstatic.com/bjh/8aaa732b3f388fe8e942602ee9db5a27.png'
                },
            ]
        }
    },
    methods: {
        goClick(item) {
            if (this.type && this.type === 'beian') {
                window.openLogin('beian')

                return false;
            }
            this.$router.push(item.url)
        }
    },
}
</script>

<style lang="scss" scoped>
.item {
    background: #e5414f;
    color: #fff;
    width: 280px;
    // margin: 0 20px;
    padding: 20px;
    border-radius: 6px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    img {
        width: 130px;
    }
    & + .item {
        margin-left: 20px;
    }
    .title {
        font-size: 26px;
        font-weight: bold;
    }
    .btn {
        background: #fff;
        color: #e5414f;
        line-height: 40px;
        display: inline-block;
        width: 130px;
        margin-top: 10px;
        border-radius: 6px;
        cursor: pointer;
    }
}
</style>